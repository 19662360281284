import React, { PureComponent } from "react"

/* Components */
import Picture from "./Picture"

/* Constants */
import { env, utils } from '../constants'


/* Component Cash */
class Cash extends PureComponent {

    render = () => {

        const { mobile, openBalance } = this.props

        if (mobile) {
            return (
                <div onClick={() => openBalance()} className="menu-select-button">
                    <Picture src={`${env.mediapoint}/images/coin.png`} alt="Cashier" />
                    <p>{utils.translate("Cashier")}</p>
                </div>
            )
        }

        return (
            <div onClick={() => openBalance()} className="balance-cash">
                <Picture src={`${env.mediapoint}/images/money-exchange.png`} alt="Money exchange" />
                <div className="balance-cash-text">{utils.translate("Cashier")}</div>
            </div>
        )
    }

}

export default Cash